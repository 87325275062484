import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/ddev-support",
  "date": "8th February 2024",
  "title": "Announcing DDEV Support",
  "summary": "Announcing initial support for DDEV development environments on Skpr projects!",
  "author": "Karl Hepworth",
  "tag": "Development",
  "tagColor": "blue",
  "tags": [{
    "name": "Local Development"
  }, {
    "name": "DDEV"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`DDEV provides containers and configuration for simple and consistent local Drupal development.`}</p>
    <p>{`Now the `}<a parentName="p" {...{
        "href": "https://www.drupal.org/docs/official_docs/local-development-guide"
      }}>{`preferred local development stack for Drupal`}</a>{`,
we're happy to announce initial support on Skpr projects!`}</p>
    <p>{`We've put together a set of documentation articles which will get DDEV running Skpr applications
locally using Skpr database images. We've also got some quality-of-life recommendations to assist
in development and testing workflows.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/202402/ddev.gif",
        "alt": "Animated image showing DDEV starting a Skpr project using the configuration provided by the\ndocumentation."
      }}></img></p>
    <p>{`This will help you develop your apps using familiar tooling for a one time setup in your Skpr
application git repository. And, we're hoping to improve and expand support for DDEV over time.`}</p>
    <p>{`Please `}<a parentName="p" {...{
        "href": "https://docs.skpr.io/setup/local/ddev/"
      }}>{`read the documentation`}</a>{` for more information and to get started.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      